import { useTranslation } from "next-i18next";
import Card from "../Card";

const RecentWork = () => {
  const { t } = useTranslation();
  return (
    <div id="RecentWork" className="w-full py-14 pt-36 bg-primary">
      <div className="text-center pb-10">
        <div className=" pb-4 flex justify-center text-3xl md:text-4xl">
          <h2 className="text-indigo-600 pr-2">Recent</h2>
          <h2 className="font-extralight">Work</h2>
        </div>
      </div>
      <div className="space-y-20 w-5/6 mx-auto">
        <div className="grid md:grid-cols-2 gap-20">
          <Card
            companyTitle="Blitz Electrique"
            companyDescription={t("blitz-electrique-description")}
            imagePath="Blitz.jpg"
            websiteLink="http://www.blitzelectrique.com/"
          />
          <Card
            companyTitle="Great Link Transport"
            companyDescription={t("transportation-company-description")}
            imagePath="Greatlink.jpeg"
            websiteLink="https://transport.greatlink.ca/"
          />
        </div>
        <div className="grid md:grid-cols-2 gap-20">
          <Card
            companyTitle="High End Transport"
            companyDescription={t("transportation-company-description")}
            imagePath="Highend.JPG"
            websiteLink="https://www.highendtransport.ca/"
          />
          <Card
            companyTitle="Reet Driving School"
            companyDescription={t("truck-driving-company-description")}
            imagePath="reetDriving.jpg"
            websiteLink="https://www.reetdriving.ca/"
          />
        </div>
      </div>
    </div>
  );
};

export default RecentWork;
