import Image from "next/image";
import Tilt from "react-parallax-tilt";
import styles from "./Card.module.css";

type CardInfo = {
  companyTitle: string;
  companyDescription: string;
  imagePath: string;
  websiteLink: string;
};

const Card = (props: CardInfo) => {
  const { companyTitle, companyDescription, imagePath, websiteLink } = props;

  return (
    <Tilt transitionSpeed={9000}>
      <div
        className={`${styles.cardOverlay} shadow-2xl h-96 w-full text-white rounded-3xl relative z-50`}
      >
        <Image
          priority={true}
          className="rounded-3xl object-cover"
          src={`/images/${imagePath}`}
          alt={"Company logo"}
          layout="fill"
        ></Image>
        <div className="relative z-50 text-center h-full flex justify-center items-center">
          <div className="w-4/5">
            <h2 className="text-4xl font-bold">{companyTitle}</h2>
            <h3 className="py-8">{companyDescription}</h3>
            <a
              href={`${websiteLink}`}
              target="_blank"
              className="rounded-md py-2 px-4 
               
                            bg-indigo-600 text-white border-solid border-2 border-indigo-600
                            focus:outline-none outline-none hover:bg-white hover:text-indigo-600
                             hover:border-indigo-600 transition duration-200 md:py-3 md:px-6"
              rel="noreferrer"
            >
              Visit Website
            </a>
          </div>
        </div>
      </div>
    </Tilt>
  );
};

export default Card;
